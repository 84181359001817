html, body {
    margin: 0;
    padding: 0;
    background-color: #1e1e1e; /* Black background */
    color: #AF9DE0; /* Purple text color */
    font-family: 'Montserrat', sans-serif; /* Montserrat font */
    font-size: 18px; /* Font size */
    line-height: 36px; /* Line height */
    font-weight: 400; /* Normal font weight */
    letter-spacing: normal; /* Normal letter spacing */
    word-spacing: 0px; /* No additional word spacing */
    text-transform: none; /* No text transformation */
    text-decoration: none solid rgb(175, 157, 224); /* No text decoration */
    text-align: start; /* Text aligned to start */
    text-indent: 0px; /* No text indent */
}
.logo {
  position: absolute;
  top: 10px; /* Adjust as necessary */
  left: 10px; /* Adjust as necessary */
  height: 50px; /* Adjust size as necessary */
  width: auto;
}
.page {
    position: relative;
    background-color: #1e1e1e; /* Dark background for the page */
    color: #FFFFFF; /* White text color */
    font-family: 'Roboto', sans-serif;
}

.title-parent {
    padding-top: 2vh;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.title {
    color: #FFFFFF;
    background-color: #2a2a2a; /* Slightly lighter than the page background */
    font-size: 56px;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

.chart-container {
    padding: 20px;
    background-color: #333333; /* Dark background for the chart area */
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    height: 40vh;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.switch-span{
    display: flex;
    justify-content: center;

}
.trade-tables-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px;
}

.trade-table {
  flex: 1; /* Each table will take equal space */
  min-width: 300px; /* Minimum width for each table */
  max-width: 600px; /* Maximum width for each table */
  margin: 10px; /* Spacing between tables */
  background-color: #2a2a2a; /* Background color for each table */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding inside each table */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  height: 400px; /* Fixed height for each table */
  overflow-y: auto; /* Enable vertical scrolling */
}

.trade-table h3 {
  text-align: center;
  color: #AF9DE0; /* Purple text color */
}

.button-container button {
    background-color: #4a4a4a; /* Dark background for buttons */
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-container button:hover {
    background-color: #626262; /* Button hover effect */
}





.parent-trade {
    max-height: 400px;
    overflow-y: auto;
    background-color: #1f1f1f; /* Dark background */
    border-radius: 8px; /* Rounded corners */
    padding: 15px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.trade {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Distribute space evenly between items */
    align-items: center; /* Vertically center items */
    border-bottom: 1px solid #333; /* Slight separation between items */
    padding: 10px 0;
    color: #fff; /* White text for readability */
}

.trade-detail {
    flex: 1; /* Allow each detail to take equal width */
    text-align: center; /* Center align the text within each detail */
    font-size: 0.9em; /* Slightly smaller text */
}

.buy {
    background-color: #004d40; /* Darker green for buy trades */
    color: #fff; /* White text */
    padding: 5px;
    border-radius: 4px;
}

.sell {
    background-color: #b71c1c; /* Darker red for sell trades */
    color: #fff; /* White text */
    padding: 5px;
    border-radius: 4px;
}





.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 10px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }