.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000; /* Black background */
}

.signin-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #222; /* Dark grey */
    padding: 40px;
    border-radius: 10px;
}

.signin-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #555; /* Dark border */
    background-color: #333; /* Dark input background */
    color: #fff; /* White text */
    font-size: 16px;
}

.signin-button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #007bff; /* Bootstrap primary color */
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signin-button:hover {
    background-color: #0056b3; /* Darker on hover */
}